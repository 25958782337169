/* phone */
@media screen and (max-width: 981px) {


    .title{
        color: white;
        font-size: 2rem;
        padding-left: 1rem;
        font-weight: 700;
    }

    .content{
        color:white;
        padding: 1.5rem 0 0 0rem;
        width:80vw;
        font-size: 1rem;
        font-family: Lora;
        font-weight: 500;
        z-index: -1;
    
        }

    .note{
    
        color:white;
        padding:0.8rem 1rem;
        margin-left: 6rem;
        position: fixed;
        bottom:3rem;
        right:5rem;
        font-size: 1rem;
        text-align:right;
        
    }


}


@media screen and  (min-width: 981px) {

    .title{
        color: white;
        font-size: 2.5rem;
        padding-left: 4rem;
        font-weight: 700;
    }
    
    .content{

    padding-top: 1rem;
    width:30vw;
    font-size: 1.2rem;
    font-family: Lora;
    font-weight: 500;
    color: white;
    z-index:10;
    margin: 0 5rem 0 0 ;
    

    }


    .note {
        color: white;
        padding: 0.8rem 1rem;
        width: 53%;
        margin: auto;
        position: fixed;
        bottom: 3rem;
        right: 5rem;
        font-size: 1.1rem;
        font-family: Lora;
        font-weight: 500;
    }

    /* .hover{
        
        padding: 0.8rem 1rem;
        margin-left: 6rem;
        position: fixed;
        bottom: 5rem;
        font-size: 1.35rem;
        font-family: Lora;
        font-weight: 500;
        border: 1px solid white;
        border-radius: 2rem;
        z-index: 50;
        color:white;
          
    } */
}