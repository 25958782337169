/* phone */
@media screen and (max-width: 981px) {

    .title{
        color: white;
        font-size: 2rem;
        padding-left: 2rem;
        font-weight: 700;
    }

    .content{
    
        width:80vw;
        padding: 1.5rem 0 0 2.5rem;
        font-size: 1rem;
        font-family: Lora;
        font-weight: 500;
        z-index: -1;
        
    
        }

    .note{
    
        color:white;
        padding: 0;
        margin: 0 0 1rem 0;
        position: fixed;
        font-size: 0.89rem;
        text-align: right;
        width:80%;
        
    }


}


@media screen and  (min-width: 981px) {

    .title{
    color: white;
    font-size: 3rem;
    padding-left: 2.5rem;
    font-weight: 700;
    }

  .content{

    padding-left: 3rem;
    width:30vw;
    font-size: 1.2rem;
    font-family: Lora;
    font-weight: 500;

    }


    .note{
       
        color:white;
        padding:0.8rem 1rem;
        margin-left: 6rem;
        position: fixed;
        bottom:3rem;
        right:5rem;
        font-size: 1.5rem;
        text-align:right;
        
    }
}