html {
  background: #000000;
  background-image: linear-gradient(black, black);
  font-family: Lora;
  font-weight: 200;
}

body {
  /* Reseting the default here */
  margin: 0;
  padding: 0;
}

#article_wrapper {
  position: relative;
  height: 20000px; /* Length of the interactive scroll article */
}

#canvas_wrapper {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.model_canvas_wrapper{
  height: 100vh;
}

.Link{
  border: white solid;
  border-radius: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: Lora;
  font-weight: 400;
  font-size: 1.5rem;
}

a {
  color: rgb(255, 236, 239);
  text-decoration: none;
  z-index: 50;
}

a:visited {
  color: rgb(255, 236, 239);
  text-decoration: none;
  z-index: 50;
}


.scroll_slide {
  color: white;
  position: absolute;
  width: 50vw;
  padding-left: 20px;
  z-index: 50;
}

h2 {
  padding-left: 0 !important;
}



.hover{
  transition: all 0.3s ease;
  background-color: black;
  color:white;
}

.hover:hover{
  background-color: #ffffff;
  color:black;
  scale: 1;
}



/* phone */
@media screen and (max-width: 981px) {
  .section1 {
      color: white;
      position: absolute;
      font-family: Unna, Auton;
      width: 80vw;
      font-size: 2.5rem;
      word-spacing:0.1rem;
      padding-left: 2.5rem !important;
      z-index: 50;
    }

    .section2 {
      font-size: 1.5rem;
      width: 90vw;
      padding-left: 1.5rem !important;
      line-height:2.5rem;
    }

    .section3 {
      font-size: 1.5rem;
      width: 90vw;
      padding-left: 1.5rem !important;
      line-height:2.5rem;
    }
    
    .section4-wrapper{
      width: 90%;
      margin: auto;
    }

    .section4-Title{
      font-size: 2rem;
      line-height: 2.5rem ; 
      word-spacing: 0.1rem ;
    }

    .section4-columns{
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between ;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .section4-left{
    width:100%;
    }

    .section4-right{
    
      width:90%;
      font-size: 1.2rem;
      font-weight: 400;
      padding-top: 0rem;
      padding-right: 1rem;
      padding-left: 1.5rem;
      padding-bottom: 1.5rem;
      line-height:1.7rem ; 
      word-spacing:0.1rem
    }



    .section5 {

      width: 80vw;
      height: 200vh;
      font-size: 1.2rem;
      text-align: center;
      padding-left: 0 !important;
      z-index: 50;
      margin: auto;
      text-align:center;     
      word-spacing:0.1rem;

    }

    .section6{
      width: 90%; 
      padding-left: 2rem;
      font-size: 1.2rem;
      word-spacing:0.1rem;
    }

    .section8-wrapper {
      padding: 2rem; 
      width: 85%; 
      margin: auto; 
      line-height:2rem ; 
      word-spacing:0.1rem;
    }

    .section9-wrapper{

      display: flex;
      flex-direction: column;
      margin:auto;
      justify-content: space-between;
      gap: 4rem;
  
      }



}




@media screen and  (min-width: 981px) {
  .section1 {
      color: white;
      position: absolute;
      font-family: Unna, Auton;
      width: 50vw;
      font-size: 4rem;
      word-spacing:0.1rem;
      padding-left: 5rem !important;
      z-index: 50;
    }
  
    .section2{
      width: 50%;
      padding-left: 5rem;
      line-height:2.5rem ;
      word-spacing:0.1rem;
    }

    .section3{
      width: 50%;
      padding: 2rem;
      margin: auto;
      line-height:2.5rem ;
      word-spacing:0.1rem;
    }

    .section4-wrapper{
      width: 60%;
      margin: auto;
    }

    .section4-Title{
      font-size: 2.2rem;
      font-family: Lora;
      font-weight: medium;
      line-height: 2.5rem ; 
      word-spacing: 0.1rem ;
    }

    .section4-columns{
      display: flex;
      flex-direction: row;
      justify-content: space-between ;
      align-items: flex-start;
      gap: 0.5rem;
    }

    .section4-left{
    width:80%;
    }

    .section4-right{
    
      width:50%;
      font-size: 1.2rem;
      font-weight: 400;
      padding-top: 0rem;
      padding-right: 1rem;
      padding-left: 1.5rem;
      line-height:1.7rem ; 
      word-spacing:0.1rem
    }
    
    .section5 {
      width: 100vw;
      height: 200vh;

      padding-left: 0 !important;
      z-index: 50;
      width: 90%; 
      padding: 4rem;
      text-align:center;    
      line-height:2.5rem ; 
      word-spacing:0.1rem;
    }
    
    .section6{
      width: 40%; 
      padding-left: 5rem;
      line-height:2.5rem ; 
      word-spacing:0.1rem;
    }

    .section8-wrapper {
      padding: 2rem; 
      width: 60%; 
      margin: auto; 
      line-height:2rem ; 
      word-spacing:0.1rem;
    }

    .section9-wrapper{

    display: flex;
    flex-direction: row;
    margin:auto;
    justify-content: space-between;
    gap: 20px;

    }
}